import React, { Component } from 'react';

export default function SearchBox() {
 return (
  <>
   <h1 className="text-primary">
    Hello world!
   </h1>
  </>
 )
}