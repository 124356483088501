import logo from './logo.svg';
import './App.scss';
import Landing from './Landing';

function App() {
  return (
    <Landing></Landing>
  );
}

export default App;
